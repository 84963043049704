import React from 'react';
import Helmet from '../project/Helmet';
import {t} from '@lingui/macro';
import CompanyDocument from './CompanyDocument';
import { createFetchCompanyCommercialTerms, createFetchCompanyEditCommercialTerms } from '../../backend/apiCalls';


/**
 * @fero
 */

class CommercialTermsPage extends React.PureComponent {
    render() {
        return <React.Fragment>
            <Helmet
                title={t`Obchodné podmineky`}
            />
            <CompanyDocument
                loadFcn={createFetchCompanyCommercialTerms()}
                saveFcn={createFetchCompanyEditCommercialTerms()}
            />
        </React.Fragment>;
    }

}

export default CommercialTermsPage;